import React from "react";
import { NavLink } from 'react-router-dom';
import './index.css';

function Footer() {
    return (
        <div id="footer">
            <div className="container">
                <p className="text-muted"><NavLink to="/home/sitemap">Site Map</NavLink>&copy; 2024</p>
            </div>
        </div>
    );
  }
    
  export default Footer;