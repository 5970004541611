import React from "react";

import HeaderContact from "../../components/header/contact";

import KitchenFloor from "../../assets/template/Home.jpg";

function Home() {
  const pageTitle = "Home";
  document.title = pageTitle + " - " + global.AppName;
  return (
    <>
      {/* Begin page content */}
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <HeaderContact></HeaderContact>
            <div className="row">
              <div className="col-md-offset-1 col-md-10">
                <h2>Welcome to A.C.Smart Tiling</h2>
                <div className="row text-center">
                  <div className="col-md-12">
                    <p>
                      Adrian Smart is a specialist floor and wall tiler with 39
                      years experience covering Oxfordshire, Gloucestershire and
                      Wiltshire.
                    </p>
                    <p>
                      Adrian provides a reliable, professional and friendly
                      service from quote to finish, priding himself on attention
                      to detail, ensuring the highest quality of workmanship
                      throughout.
                    </p>
                    <p>
                      He will personally visit you for a no obligation quote,
                      which will include taking measurements, providing tile
                      quantity calculations and offer advice if required.
                    </p>
                    <p>Call Adrian anytime to discuss your project.</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-offset-3 col-md-4 home-list">
                    <ul>
                      <li className="bold">Competitive Prices</li>
                      <li className="bold">Traditional to Contemporary</li>
                      <li className="bold">No Obligation Quotes</li>
                      <li className="bold">All Work Guaranteed</li>
                      <li className="bold">Fully Insured</li>
                      <li className="bold">Repair Work</li>
                      <li className="bold">Domestic and Commercial</li>
                      <li className="bold">No Job Too Small</li>
                    </ul>
                  </div>
                  <div className="col-md-3 home-list">
                    <img src={KitchenFloor} alt="Kitchen Floor" height="300" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Home;
