import React from "react";

function HeaderContact() {
  return (
    <>
      <div className="contacts">Telephone 01993 851694 Mobile 07850 064863</div>
    </>
  );
}

export default HeaderContact;
