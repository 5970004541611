import React from "react";

import HeaderContact from "../../components/header/contact";

function Privacy() {
  const pageTitle = "Privacy Policy";
  document.title = pageTitle + " - " + global.AppName;
  return (
    <>
      {/* Begin page content */}
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <HeaderContact></HeaderContact>
            <div className="row">
              <div className="col-md-offset-1 col-md-10">
                <h2>{pageTitle}</h2> 
                <p>
                  We will only collect the personal information that you give us
                  when you contact us to get back to you.
                </p>
                <p>
                  We treat your details as we would want ours treated and
                  secured by other websites.
                </p>
                <h3>How do we keep your data safe?</h3>
                <p>
                  We use the secure server technology and encryption where
                  appropriate to ensure this information; it is protected to the
                  highest standards.
                </p>
                <h3>Do we disclose your information to outside parties?</h3>
                <p>
                  We will <strong>never</strong> pass your details onto a third
                  party.
                </p>
                <h3>Links to third party sites</h3>
                <p>
                  Within this site there are links to third party websites.
                  These third party websites have separate and independent
                  privacy policies. We therefore have no responsibility or
                  liability for the content and activity of these third party
                  sites.
                </p>
                <h3>Questions?</h3>
                <p>
                  If you have questions about your personal data or our privacy
                  policy please use the Contacts Page to get in touch.
                </p>
                <h3>Your Consent</h3>
                <p>
                  By using this website you consent to our web site privacy
                  policy.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Privacy;
