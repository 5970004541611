import { React, useEffect } from "react";
// import { useNavigate } from "react-router-dom";

function Feedback() {
  const pageTitle = "Redirect";
  document.title = pageTitle + " - " + global.AppName;

//   let navigate = useNavigate();
//   useEffect(() => {
//     navigate("http://maps.google.com/maps/place?cid=11890353120296775028&q=A+C+Smart+Tiling,+1+Primrose+Cottages,+Bampton,+OX18+2JA,+United+Kingdom&hl=en&ved=0CBwQ-QswAA&sa=X&ei=8NlRTYq3EofOjAf7q_m5Aw", 
//         { replace: true });
//     }, []);

useEffect(() => {
    window.location.href = "http://maps.google.com/maps/place?cid=11890353120296775028&q=A+C+Smart+Tiling,+1+Primrose+Cottages,+Bampton,+OX18+2JA,+United+Kingdom&hl=en&ved=0CBwQ-QswAA&sa=X&ei=8NlRTYq3EofOjAf7q_m5Aw";
  }, []);

  return <div>test</div>;
}

export default Feedback;