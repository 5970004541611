import React from "react";

import HeaderContact from "../../components/header/contact";

function App() {
  const pageTitle = "Testimonials";
  document.title = pageTitle + " - " + global.AppName;
  return (
    <>
      {/* Begin page content */}
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <HeaderContact></HeaderContact>

            <div className="row">
              <div className="col-md-offset-1 col-md-10">
                <h2>Recommendations</h2>
                <p>A. C. Smart Tiling is recommended by:</p>
                <ul>
                  <li>
                    <a href="http://www.tilesofstow.co.uk" title="Tiles of Stow" target="_blank" rel="noopener noreferrer" >Tiles of Stow</a>
                  </li>
                  <li>Cotteswood Kitchens - (Bespoke Kitchens)
                  </li>
                  <li>
                    <a href="https://www.kpbc.co.uk/" title="Keith Pulham Builders" target="_blank" rel="noopener noreferrer" >Keith Pulham Builders</a> - (Stow-on-the-wold)
                  </li>
                  <li>
                    <a href="http://www.jasonstrongbuilders.co.uk" title="Jason Strong Builders" target="_blank" rel="noopener noreferrer">Jason Strong Builders</a>
                  </li>
                  <li>
                    <a href="http://www.pethersofburford.co.uk" title="Pethers of Burford" target="_blank" rel="noopener noreferrer">Pethers of Burford</a>
                  </li>
                  <li>
                    <a href="http://www.jervishomes.co.uk" title="Henry Jervis" target="_blank" rel="noopener noreferrer" >Henry Jervis</a>
                  </li>
                  <li>S A & M A Wilson Ltd</li>
                  <li>S Mustoe & Sons Ltd</li>
                </ul>

                <br />

                <h2>Customer Testimonials</h2>
                <ul>
                  <li className="listpadded">
                    "Adrian has completed four separate pieces of work for us
                    over a number of years to our complete satisfaction. We have
                    always been able to rely on his helpful advice and expertise
                    to make easy decisions." - Jo and Paul Ader
                  </li>
                  <li className="listpadded">
                    "I have been delighted with the quality of tiling that
                    Adrian did in my bathroom. The space was really tricky and
                    he made it look fantastic" - Su Allen
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default App;
