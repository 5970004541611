import { useEffect, useState } from "react";
import { NavLink } from 'react-router-dom';

import HeaderContact from "../../components/header/contact";

function Sitemap() {
  const pageTitle = "Site Map";
  document.title = pageTitle + " - " + global.AppName;

  const [sitemap, setSitemap] = useState([]);

  useEffect(() => {
    fetch("/sitemap.xml")
      .then((response) => response.text())
      .then((xmlString) => {
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(xmlString, "text/xml");
        const urls = xmlDoc.getElementsByTagName("url");
        const sitemapList = [];
        for (let i = 0; i < urls.length; i++) {
            const loc =
                urls[i].getElementsByTagName("loc")[0].childNodes[0].nodeValue;
            const titleNode = urls[i].getElementsByTagNameNS(
                "http://www.heritagepigs.co.uk/schemas/sitemap",
                "title"
            )[0];
            const title = titleNode ? titleNode.childNodes[0].nodeValue : loc;
            const descriptionNode = urls[i].getElementsByTagNameNS(
                "http://www.heritagepigs.co.uk/schemas/sitemap",
                "description"
            )[0];
            const description = descriptionNode ? descriptionNode.childNodes[0].nodeValue : loc;
            sitemapList.push(
                <li key={i}>
                <NavLink to={loc}>{title}</NavLink> - {description}
                </li>
            );
        }
        setSitemap(sitemapList);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  return (
    <>
      {/* Begin page content */}
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <HeaderContact></HeaderContact>
            <div className="row">
              <div className="col-md-offset-1 col-md-10">
                <h2>{pageTitle}</h2>
                <ul>{sitemap}</ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Sitemap;
