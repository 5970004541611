import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';

import HeaderContact from "../../components/header/contact";
import { Grid } from 'react-loader-spinner'

import LightGallery from 'lightgallery/react';

// import styles
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';

function Room() {
  let params = useParams();
  // const [pagetitle, setPagetitle] = useState();
  // setPagetitle(useState(params.room.charAt(0).toUpperCase() + params.room.slice(1)));
  const pagetitle = params.room.charAt(0).toUpperCase() + params.room.slice(1);
  document.title = pagetitle + " - " + global.AppName;
  const photosFile = '/photos/Photos-' + pagetitle + '.json';
  const photosPath = "/photos/" + pagetitle + "/";
  const photosThumbnailsPath = photosPath + "Thumbnails/";
  const [entities, setEntities] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetch(photosFile)
      .then(response => response.json())
      .then(data => {
        setEntities(data.photos);
      })
      .catch(error => {
        console.error('Error:', error);
      })
      .finally(setLoading(false));
  }, [pagetitle]);


  return (
    <>
      {/* Begin page content */}
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <HeaderContact></HeaderContact>   
            <div className="offset2 span9">
              <h2>{pagetitle} Gallery</h2>
              {loading ? (
                <Grid
                  visible={true}
                  height="80"
                  width="80"
                  color="#88a7bc"
                  ariaLabel="grid-loading"
                  radius="12.5"
                  wrapperStyle={{}}
                  wrapperClass="grid-wrapper" />
              ) : (
                <div className="gallerythumbnails">                  
                  <LightGallery speed={500} download={false}>
                        {entities.map(photos => (
                            <div key={photos.filename} data-src={photosPath + photos.filename + ".jpg"}>
                                <img src={photosThumbnailsPath + photos.filename + ".jpg"} alt={photos.title} />
                            </div>
                        ))}
                  </LightGallery>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Room;
