import React from 'react';

import HeaderContact from "../../components/header/contact";

function App() {
    const pageTitle = '404';
    document.title = pageTitle + ' - ' + global.AppName;
    return (
        <>
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <HeaderContact></HeaderContact>
            
                        <div className="row">
                            <div className="col-md-offset-1 col-md-10">
                                <h2>Oops</h2>
                                <div className="row text-center">
                                    <div className="col-md-12">
                                        <p>
                                            The page you are looking for does not exist. Use the menu above to navigate the site.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default App;