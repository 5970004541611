import React from "react";

import HeaderContact from "../../components/header/contact";

function App() {
  const pageTitle = "Services";
  document.title = pageTitle + " - " + global.AppName;
  return (
    <>
    
      {/* Begin page content */}
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <HeaderContact></HeaderContact>

            <div className="row">
              <div className="col-md-offset-1 col-md-10">
                <h2>Services</h2>
                <div className="text-center">
                  <p>
                    <strong>
                      Quotes, Tile Quantity Calculations and Advice are FREE
                      with no obligation
                    </strong>
                  </p>
                  <p>
                    <strong>
                      Adrian's work is varied and encompasses many different
                      designs from intricate mosaic to domestic bathrooms and
                      kitchens to large open areas
                    </strong>
                  </p>
                </div>
                <h3>Services can Include</h3>
                <ul>
                  <li>
                    <span>Stripping off the original tiles</span>
                  </li>
                  <li>
                    <span>Preparation of surfaces to be tiled</span>
                  </li>
                  <li>
                    <span>Tile edge polishing</span>
                  </li>
                  <li>
                    <span>Can supply fixing materials, sealers and grouts</span>
                  </li>
                  <li>
                    <span>Can lay and tile onto underfloor heating</span>
                  </li>
                  <li>
                    <span>Wet rooms</span>
                  </li>
                </ul>
                <h3>All Types of Tile Fitted</h3>
                <ul>
                  <li>
                    <span>Ceramic</span>
                  </li>
                  <li>
                    <span>Porcelain</span>
                  </li>
                  <li>
                    All Natural Stone Tiles
                    <ul>
                      <li>
                        <span>Marble</span>
                      </li>
                      <li>
                        <span>Travertine</span>
                      </li>
                      <li>
                        <span>Limestone</span>
                      </li>
                      <li>
                        <span>Slate</span>
                      </li>
                      <li>
                        <span>Granite</span>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <span>Glass</span>
                  </li>
                  <li>
                    <span>Mosaic</span>
                  </li>
                  <li>
                    <span>Pebble</span>
                  </li>
                  <li>
                    <span>Terracotta</span>
                  </li>
                  <li>
                    <span>Victorian</span>
                  </li>
                  <li>
                    <span>Geometric Tiling</span>
                  </li>
                </ul>
                <h3>Commercial Tiling</h3>
                <p>
                  Adrian has worked for domestic and commercial contractors and
                  has tiled in shops, garages, pubs, restaurants, industrial
                  units and swimming pools.
                </p>
                <ul>
                  <li>
                    <span>Liability Insured</span>
                  </li>
                  <li>
                    <span>CIS Registered</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default App;
