import React from "react";
import { NavLink } from 'react-router-dom';
import './index.css';

function Header({ currentPage }) {
    function toggleMobileMenu() {
        var x = document.getElementById("menuItems");
        x.classList.toggle("in");
        
    }
    return (
        <>
            {/* Fixed navbar */}

            <div className="navbar navbar-default navbar-fixed-top" role="navigation">
                <div className="container">
                    <div className="navbar-header">
                        <button type="button" className="navbar-toggle" data-toggle="collapse" data-target=".navbar-collapse">
                            <span className="sr-only">Toggle navigation</span>
                            <span className="icon-bar"></span>
                            <span className="icon-bar"></span>
                            <span className="icon-bar"></span>
                        </button>                        
                    </div>

                    <div id="menuItems" className="collapse navbar-collapse">
                        <ul className="nav navbar-nav">
                            <li><NavLink to="/" onClick={toggleMobileMenu}>Home</NavLink></li>
                            <li><NavLink to="/services" onClick={toggleMobileMenu}>Services</NavLink></li>
                            <li className="dropdown">
                                <a href="#" className="dropdown-toggle" data-toggle="dropdown">Galleries <b className="caret"></b></a>
                                <ul className="dropdown-menu">
                                    <li><NavLink to="/gallery/bathrooms" onClick={toggleMobileMenu}>Bathrooms</NavLink></li>
                                    <li><NavLink to="/gallery/floors" onClick={toggleMobileMenu}>Floors</NavLink></li>
                                    <li><NavLink to="/gallery/geometrics" onClick={toggleMobileMenu}>Geometrics</NavLink></li>
                                    <li><NavLink to="/gallery/kitchens" onClick={toggleMobileMenu}>Kitchens</NavLink></li>
                                    <li><NavLink to="/gallery/projects" onClick={toggleMobileMenu}>Projects</NavLink></li>
                                </ul>
                            </li>
                            <li><NavLink to="/home/testimonials" onClick={toggleMobileMenu}>Testimonials</NavLink></li>
                            <li><NavLink to="/home/faq" onClick={toggleMobileMenu}>Q + A</NavLink></li>
                            <li><NavLink to="/contact" onClick={toggleMobileMenu}>Contact</NavLink></li>
                            {/* <li><NavLink to="/home/feedback">Feedback</NavLink></li> */}
                            <li><NavLink to="http://maps.google.com/maps/place?cid=11890353120296775028&q=A+C+Smart+Tiling,+1+Primrose+Cottages,+Bampton,+OX18+2JA,+United+Kingdom&hl=en&ved=0CBwQ-QswAA&sa=X&ei=8NlRTYq3EofOjAf7q_m5Aw" target="">Feedback</NavLink></li>
                        </ul>

                    </div>
                    {/* nav-collapse */}
                </div>
            </div>

            <div className="page-header">
                <h1>A C SMART<br />TILING</h1>
            </div>
            {
                process.env.NODE_ENV === 'development' && 
                <div id="devbuild">
                    This is a Dev Build
                </div>
            }
            <noscript>You need to enable JavaScript to run this app.</noscript>
        </>
      
    );
  }
    
  export default Header;




