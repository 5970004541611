import React from "react";

import HeaderContact from "../../components/header/contact";

function App() {
  const pageTitle = "FAQs";
  document.title = pageTitle + " - " + global.AppName;
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <HeaderContact></HeaderContact>

            <div className="row">
              <div className="col-md-offset-1 col-md-10">
                <h2>Frequently Asked Questions</h2>
                <div className="question">Q.1 How long will the job take?</div>
                <div className="answer">
                  <table cellspacing="0">
                    <tbody>
                      <tr>
                        <td>Average sized 3 bed house kitchen walls</td>
                        <td>=</td>
                        <td className="right">1 Day</td>
                      </tr>
                      <tr>
                        <td>Average sized 3 bed house kitchen floor</td>
                        <td>=</td>
                        <td className="right">1 Day</td>
                      </tr>
                      <tr>
                        <td>Bathroom walls</td>
                        <td>=</td>
                        <td className="right">1 Day</td>
                      </tr>
                      <tr>
                        <td>Bathroom floor</td>
                        <td>=</td>
                        <td className="right">
                          <sup>1</sup>/<sub>2</sub> Day
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <br />
                <div className="question">
                  Q.2 Can I walk on the floor the same day as it has been tiled?
                </div>
                <div className="answer">Yes, being careful of grout joints</div>
                <br />
                <div className="question">Q.3 How much will it cost?</div>
                <div className="answer">
                  Every job is different, I can give you an idea over the phone,
                  but ideally I would need to see the area and would be able to
                  give you "on the spot" free no obligation quote
                </div>
                <br />
                <div className="question">
                  Q.4 Can I put floor tiles on walls?
                </div>
                <div className="answer">
                  Yes. However, there are specific weight restrictions as
                  follows:
                  <table cellspacing="0">
                    <tbody>
                      <tr>
                        <td>Tiling onto plastered walls</td>
                        <td>=</td>
                        <td className="right">
                          20kg per m<sup>2</sup>
                        </td>
                      </tr>
                      <tr>
                        <td>Tiling onto plaster board walls</td>
                        <td>=</td>
                        <td className="right">
                          30kg per m<sup>2</sup>
                        </td>
                      </tr>
                      <tr>
                        <td>Tiling onto backer boards</td>
                        <td>=</td>
                        <td className="right">
                          40kg per m<sup>2</sup>
                        </td>
                      </tr>
                      <tr>
                        <td>Fibreglass re-inforced cement sheet</td>
                        <td>=</td>
                        <td className="right">
                          50kg per m<sup>2</sup>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <br />
                <div className="question">
                  Q.5 Can you tile onto timber floors?
                </div>
                <div className="answer">
                  Yes, provided they are solid with no movement. Floorboards
                  would need an over lay of min. <sup>1</sup>/<sub>2</sub> inch
                  ply
                </div>
                <br />
                <div className="question">Q.6 What areas do you cover?</div>
                <div className="answer">
                  <ul>
                    <li>Stow-on-the-Wold</li>
                    <li>Chipping Norton</li>
                    <li>Faringdon</li>
                    <li>North Leach</li>
                    <li>Fairford</li>
                    <li>Cirencester</li>
                    <li>Lechlade</li>
                  </ul>
                  and anywhere else in
                  <ul>
                    <li>The Cotswolds</li>
                    <li>Oxfordshire</li>
                    <li>Gloucestershire</li>
                    <li>Wiltshire</li>
                  </ul>
                </div>
                <p>
                  If you have any other questions then please{" "}
                  <a href="/Contact">get in touch</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default App;
