import React from "react";
import { useParams } from 'react-router-dom';

import HeaderContact from "../../components/header/contact";

function Gallery() {
  const pageTitle = "Gallery";
  document.title = pageTitle + " - " + global.AppName;
  return (
    <>
      {/* Begin page content */}
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <HeaderContact></HeaderContact>

            {/* <h2>Photo Galleries</h2> {room} */}

            <div className="row">
              <div className="gallerythumbnail col-sm-6 col-md-4">
                <h3>Bathrooms</h3>
                <a href="/Gallery/Bathrooms" title="Bathrooms">
                  <img src="/photos/bathrooms/Thumbnails/Bathroom38.jpg" alt="Bathrooms" />
                </a>
              </div>
              <div className="gallerythumbnail col-sm-6 col-md-4">
                <h3>Floors</h3>
                <a href="/Gallery/Floors" title="Floors">
                  <img src="/photos/bathrooms/Thumbnails/Bathroom29.jpg" alt="Floors" />
                </a>
              </div>
              <div className="clearfix visible-sm"></div>
              <div className="gallerythumbnail col-sm-6 col-md-4">
                <h3>Geometrics</h3>
                <a href="/Gallery/Geometrics" title="Geometrics">
                  <img src="/photos/geometrics/Thumbnails/Geometric01.jpg" alt="Geometrics" />
                </a>
              </div>
              <div className="gallerythumbnail col-sm-6 col-md-4">
                <h3>Kitchens</h3>
                <a href="/Gallery/Kitchens" title="Kitchens">
                  <img src="/photos/kitchens/Thumbnails/Kitchen10.jpg" alt="Kitchens" />
                </a>
              </div>
              <div className="clearfix visible-sm"></div>
              <div className="gallerythumbnail col-sm-6 col-md-4">
                <h3>Projects</h3>
                <a href="/Gallery/Projects" title="Projects">
                  <img src="/photos/projects/Thumbnails/Project05.jpg" alt="Projects" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Gallery;
